exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advanced-editor-feedback-js": () => import("./../../../src/pages/advanced-editor-feedback.js" /* webpackChunkName: "component---src-pages-advanced-editor-feedback-js" */),
  "component---src-pages-case-studies-aj-produkter-index-jsx": () => import("./../../../src/pages/case-studies/aj-produkter/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-aj-produkter-index-jsx" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-case-studies-tenson-index-jsx": () => import("./../../../src/pages/case-studies/tenson/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-tenson-index-jsx" */),
  "component---src-pages-contact-sales-js": () => import("./../../../src/pages/contact-sales.js" /* webpackChunkName: "component---src-pages-contact-sales-js" */),
  "component---src-pages-contact-support-js": () => import("./../../../src/pages/contact-support.js" /* webpackChunkName: "component---src-pages-contact-support-js" */),
  "component---src-pages-examples-aj-industry-js": () => import("./../../../src/pages/examples/aj-industry.js" /* webpackChunkName: "component---src-pages-examples-aj-industry-js" */),
  "component---src-pages-examples-aj-office-js": () => import("./../../../src/pages/examples/aj-office.js" /* webpackChunkName: "component---src-pages-examples-aj-office-js" */),
  "component---src-pages-examples-aj-school-js": () => import("./../../../src/pages/examples/aj-school.js" /* webpackChunkName: "component---src-pages-examples-aj-school-js" */),
  "component---src-pages-examples-js": () => import("./../../../src/pages/examples.js" /* webpackChunkName: "component---src-pages-examples-js" */),
  "component---src-pages-examples-tenson-hurricane-js": () => import("./../../../src/pages/examples/tenson-hurricane.js" /* webpackChunkName: "component---src-pages-examples-tenson-hurricane-js" */),
  "component---src-pages-examples-tenson-jacket-mens-js": () => import("./../../../src/pages/examples/tenson-jacket-mens.js" /* webpackChunkName: "component---src-pages-examples-tenson-jacket-mens-js" */),
  "component---src-pages-examples-tenson-jacket-womens-js": () => import("./../../../src/pages/examples/tenson-jacket-womens.js" /* webpackChunkName: "component---src-pages-examples-tenson-jacket-womens-js" */),
  "component---src-pages-examples-tenson-teddy-js": () => import("./../../../src/pages/examples/tenson-teddy.js" /* webpackChunkName: "component---src-pages-examples-tenson-teddy-js" */),
  "component---src-pages-examples-tenson-txlite-js": () => import("./../../../src/pages/examples/tenson-txlite.js" /* webpackChunkName: "component---src-pages-examples-tenson-txlite-js" */),
  "component---src-pages-feature-request-js": () => import("./../../../src/pages/feature-request.js" /* webpackChunkName: "component---src-pages-feature-request-js" */),
  "component---src-pages-free-trial-confirmation-js": () => import("./../../../src/pages/free-trial/confirmation.js" /* webpackChunkName: "component---src-pages-free-trial-confirmation-js" */),
  "component---src-pages-free-trial-index-js": () => import("./../../../src/pages/free-trial/index.js" /* webpackChunkName: "component---src-pages-free-trial-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-content-standards-policy-jsx": () => import("./../../../src/pages/legal/content-standards-policy.jsx" /* webpackChunkName: "component---src-pages-legal-content-standards-policy-jsx" */),
  "component---src-pages-legal-cookies-jsx": () => import("./../../../src/pages/legal/cookies.jsx" /* webpackChunkName: "component---src-pages-legal-cookies-jsx" */),
  "component---src-pages-legal-data-processing-jsx": () => import("./../../../src/pages/legal/data-processing.jsx" /* webpackChunkName: "component---src-pages-legal-data-processing-jsx" */),
  "component---src-pages-legal-privacy-jsx": () => import("./../../../src/pages/legal/privacy.jsx" /* webpackChunkName: "component---src-pages-legal-privacy-jsx" */),
  "component---src-pages-legal-terms-jsx": () => import("./../../../src/pages/legal/terms.jsx" /* webpackChunkName: "component---src-pages-legal-terms-jsx" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

